import React, { useEffect, useState } from "react";
import { toDataURL } from "qrcode";
import { useIsMobile } from "../../utils/useIsMobile";
import apple from "./apple.png";
import google from "./google.png";
import styles from "./styles.module.css";

const title = "Download the tokenproof app before proceeding";
const subitleWeb =
  "Finish your enrollment after having the app installed by tapping on this QR Code";
const subitleMobile =
  "Finish your enrollment after having the app installed by tapping this button";

export const QRCode: React.FC = () => {
  const { isMobile } = useIsMobile();

  const [qr, setQR] = useState("");
  const { host, pathname } = new URL(window.location.href);

  useEffect(() => {
    toDataURL(window.location.href, (err, url) => setQR(url));
  }, []);

  const intentURL = `intent://${host}${pathname}#Intent;scheme=https;package=xyz.tokenproof.wallet;end`;

  const isAndroid = window.navigator.userAgent.includes("Android");

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>

        <div className={styles.buttons}>
          <a
            href="https://apps.apple.com/mx/app/tokenproof/id1605810422"
            target="_blank"
            rel="noreferrer"
          >
            <img src={apple} alt="" className={styles.storeIcon} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=xyz.tokenproof.wallet"
            target="_blank"
            rel="noreferrer"
          >
            <img src={google} alt="" className={styles.storeIcon} />
          </a>
        </div>

        <div className={styles.text}>
          {isMobile ? subitleMobile : subitleWeb}
        </div>

        {isMobile ? (
          <div className={styles.deeplinkContainer}>
            <a
              href={isAndroid ? intentURL : window.location.href}
              className={styles.deeplink}
            >
              <span>Open tokenproof app</span>
            </a>
          </div>
        ) : (
          <div className={styles.qrContainer}>
            <a href={isAndroid ? intentURL : window.location.href}>
              <img src={qr} alt="" className={styles.qr} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
